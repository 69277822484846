/* You can add global styles to this file, and also import other style files */
@import 'front/variable';
@import 'front/theme';

@import 'front/mixins/spacing';
@import 'front/mixins/color';
@import 'front/mixins/grid';
@import 'front/mixins/font';

@import 'front/component/global';
@import 'front/component/heading';

@import 'front/component/header';
@import 'front/component/sidebar';
@import 'front/component/spinner';
@import 'front/component/card';
@import 'front/component/dashboard';

@import 'front/responsive';

body {
  margin: 0;
}

.no-wrap {
  white-space: nowrap;
}

/* MODAL */
.cdk-overlay-backdrop {
  &.cdk-overlay-backdrop-showing {
    background: rgba(0, 0, 0, .32) !important;
    opacity: 1 !important;
  }
}

.cdk-drag.list-item {
  border-bottom: 1px solid #6b2394 !important;
}

.cdk-drop-list.list {
  border: 1px solid #6b2394 !important;
}

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.text-align-center {
  text-align: center;
}

@media screen and (max-width: 1318px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
    padding: 8px 24px !important;
    white-space: nowrap; //no break line
  }
}

.box-custom {
  display: flex;
  align-items: center;

  &-link {
    margin: 4px 4px 0;
    display: inline-block;
  }
}

.flex-row {
  flex-direction: row;
}

.gap-15 {
  gap: 15px;
}

.gap-20 {
  gap: 20px;
}

.mw-55 {
  max-width: 55%;
}

.full-width-table {
  width: 100%;
}

.header {
  z-index: 200000;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  padding: 20px;
  position: fixed;
  justify-content: space-between;
  gap: 20px;

  &-blur {
    backdrop-filter: blur(6px);
    background: rgb(114 41 128 / 59%);
  }

  &-nav {
    display: flex;
    align-items: center;
    flex-grow: 1;

    @media(max-width: 900px) {
      flex-direction: column;
      align-items: start;
    }
  }

  strong {
    color: #ffffff;
  }

  &-logo {
    display: flex;

    b {
      color: #B6F230;
    }

    &-col {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
    }
  }

  &-content {
    @media(max-width: 900px) {
      display: none
    }
    width: 85%;
    display: flex;
    justify-content: space-between;

    &-link {
      color: #ffffff;
      font-weight: bold;
      cursor: pointer;
      padding: 0 20px;
    }
  }

  &-content-mobile {
    @media(min-width: 900px) {
      display: none;
    }

    display: flex;

    &-inside {
      width: 100%;
      transition-delay: 0s;
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 103%;
      padding: 10px;
      background-color: #834896;
      transition: right 0.3s ease;
    }
  }

  &-btn {
    @media(max-width: 900px) {
      justify-content: start;
      a {
        padding: 30px !important;
        border-radius: 10px !important;
      }
    }
    width: 40%;
    display: flex;
    justify-content: end;

    a {
      color: #8A549E;
      border-radius: 10px 0 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      margin-right: -20px;
      padding: 30px 30px 30px 50px;
      text-align: center;
      background-color: #30F232;
      font-weight: bold;
    }
  }
}

.menuimg {
  width: 40px;
  height: 33px;

}

.header-content-mobile:hover .header-content-mobile-inside {
  right: 0;
  width: 100%;
  background-color: #834896;
  transition: right 0.3s ease, width 0.3s ease;
  transition-delay: 0s;

}

mat-form-field:only-child {
  /* Add your custom styles here */
  border: none; /* Example: Add a red border */
  border-radius: 8px; /* Example: Add rounded corners */
  color: #ffffff;
  font-size: 25px;
  font-weight: bold;

}

mat-form-field.mat-form-field-appearance-fill {
  /* Remove the border */
  border: none !important;
}

.logo {
  width: 50px;
  height: 50px;
}

.w-100 {
  display: flex;
  justify-content: center;
  width: 100%
}

#testimonials {
  .slick-list {
    z-index: 100;
  }
}


.slick-dots li button{
  border-radius: 100% !important;
  background-color:  #834896 !important;

}

.slick-dots li button:before{
  color: #30F232 !important;
  font-size: 14px !important;
  line-height: 22px !important;
}
.slick-dots{
  position: absolute !important;
  bottom: -150px !important;
  @media(max-width: 900px){
    position: absolute !important;
    bottom: -110px !important;
  }
}
